<template>
    <div class="auth--layout h-100">
        <div class="header__sec">
            <div class="layer--inner">
                <div class="effect__bg--left"></div>
                <SocaLogo />
                <div class="effect__bg--right"></div>
            </div>
        </div>

        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-6 d-flex align-items-center justify-content-start h-100">
                    <h1 class="auth__banner text-left">unlocking <br> <b>Superhuman</b> <br> potentials</h1>
                </div>
                <div class="col-md-6 h-100">
                    <div class="container--content h-100">
                        <router-view ></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SocaLogo } from "@/components/icons/creator/index.js"
export default{
    components: {
        SocaLogo
    },
    data(){
        return{

        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
.auth--layout{
    background: #FFF;
}

.container--content{
    padding-right: 5rem;
}

.auth__banner b{
    color: #000;
    font-family: "EB Garamond";
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
}
.auth__banner{
    padding-left: 7rem;
    color: #000;
    font-family: "EB Garamond";
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
}
.effect__bg--left{
    border-radius: 69px;
    background: rgba(150, 0, 249, 0.30);
    filter: blur(150px);
    width: 500px;
    height: 325px;
    position: absolute;
    top: -211px;
    left: 0;
}
.effect__bg--right{
    border-radius: 69px;
    background: rgba(75, 5, 249, 0.30);
    filter: blur(130px);
    width: 980px;
    height: 450px;
    position: absolute;
    top: -211px;
    right: -227px;
}
.header__sec{
    padding: 2rem 0rem 0rem 7rem;
    position: fixed;
    width: 100%;
}
.layer--inner{
    width: 100%;
    display: flex;
    overflow: hidden;
}
</style>